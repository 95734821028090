import { Modal, Input, Form, message } from 'antd'
import useForm from 'shared/hooks/useForm'
import { connect } from 'react-redux'
import { rpc } from 'shared/actions'
import React from 'react'

import './EmailIndependentTeachersTestModal.less'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  }
}

const EmailIndependentTeachersTestModal = ({ rpc, onOk, ...props }) => {
  const { handleSubmit, getFieldProps, getFieldError, isSubmitting } = useForm({
    onSubmit: vals => {
      const emails = vals.emails
        .split('\n')
        .map(email => email.trim())
        .filter(email => email)
      return rpc(`user.emailIndependentTeachersTest`, { emails })
        .then(() => {
          message.success('Emails Sent.')
          onOk()
        })
        .catch(e => {
          const { errorDetails, error, failed } = e
          if (error === 'validation_error') {
            const invalidEmails = errorDetails.map(({ field }) => {
              const index = field.split('.')[1]
              return emails[index]
            })
            message.error(
              `No emails been sent. Error - check invalid emails input: ${invalidEmails}`
            )
          } else if (failed) {
            const emailsNotSent = failed.map(e => e.email)
            message.error(`Error sending email to ${emailsNotSent.join(', ')}`)
          }
        })
    },
    initialValues: {
      emails: ''
    }
  })

  return (
    <Modal
      {...props}
      confirmLoading={isSubmitting}
      okText='Send Emails'
      onOk={handleSubmit}
      title='Independent Teacher Email Test'
    >
      <Form className='align-label-left'>
        <Form.Item
          {...formItemLayout}
          {...getFieldError('emails')}
          label='Emails'
        >
          <Input.TextArea
            {...getFieldProps('emails')}
            autoFocus
            placeholder='Email Addresses (separated by next line)'
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

/** @todo propTypes */

export default connect(null, { rpc })(EmailIndependentTeachersTestModal)
